<template>
  <b-modal
    :active.sync="active"
    animation
    hasModalCard
    :canCancel="['escape', 'outside']"
    class="dialog strong-confirmation-dialog">
    <div class="modal-card">
      <header
        v-if="title"
        class="modal-card-head">
        <p class="modal-card-title">
          {{ title }}
        </p>
      </header>
      <section
        class="modal-card-body"
        :class="{'is-titleless': !title}">
        <p v-html="message"/>
        <b-field>
          <b-input
            ref="input"
            v-model="input"
            @keypress.enter.native="onConfirm"/>
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <b-button @click="onCancel">
          {{ cancelText }}
        </b-button>
        <b-button
          type="is-danger"
          :disabled="isConfirmDisabled"
          @click="onConfirm">
          {{ confirmText }}
        </b-button>
      </footer>
    </div>
  </b-modal>
</template>

<script>
import get from 'lodash/get'

export default {
  name: 'strong-confirmation-dialog',
  data() {
    return {
      active: false,
      params: null,
      input: null
    }
  },
  computed: {
    title() {
      return get(this.params, 'title', '')
    },
    message() {
      return get(this.params, 'message', '')
    },
    confirmText() {
      return get(this.params, 'confirmText', this.$t('strongConfirmationDialog.default.confirmText'))
    },
    cancelText() {
      return get(this.params, 'cancelText', this.$t('strongConfirmationDialog.default.cancelText'))
    },
    isConfirmDisabled() {
      return this.input !== get(this.params, 'input', '')
    }
  },
  methods: {
    open(data) {
      this.params = data

      this.active = true
      this.$emit('open')

      this.$nextTick(() => {
        this.$refs.input.focus()
      })
    },
    close() {
      this.input = null
      this.active = false
      this.$emit('close')
    },
    onConfirm() {
      if (this.isConfirmDisabled) return

      if (get(this.params, 'onConfirm', null)) {
        this.params.onConfirm()
      }
      this.close()
    },
    onCancel() {
      if (get(this.params, 'onCancel', null)) {
        this.params.onCancel()
      }
      this.close()
    }
  }
}
</script>

<style lang="scss" scoped>
.strong-confirmation-dialog {
  color: $primary-ink;

  .modal-card {
    max-width: 320px;
  }
}
</style>
